import React from "react"
import styled from "styled-components"
import { PostDate } from "../../common/general-styles.component"
import { SecondaryHeading } from "../../common/heading.component"
import { Image } from "../../common/image.component"
import {
  BaseLink,
  NeutralBoldRawLink,
} from "../../../ui/components/link/link.component"
import Tag from "../../common/tag.component"
import { BlogPostPreview } from "./blog.interface"
import { Navigation, Slider, Slides } from "../../common/slider/slider"
import { AppFunctionComponent } from "../../../types"
import useFallbackImage from "../../../hooks/use-fallback-image.hook"
import { IGatsbyImageData } from "gatsby-plugin-image"

const FeaturedStoriesNavigation = styled(Navigation)`
  align-self: end;
  grid-area: 4 / 1 / 4 / 1;

  @media (min-width: 640px) {
    grid-area: 3 / 2 / 3 / 2;
  }
`

const ContentWraper = styled.div`
  @media (min-width: 640px) {
    margin: 65px 0;
    max-width: 460px;
  }
`

const Heading = styled(SecondaryHeading)`
  grid-area: 1 / 1 / 1 / 1;

  @media (min-width: 640px) {
    grid-area: 1 / 1 / 1 / 3;
    margin: 0;
  }
`

const ContainerSlider = styled(Slider)`
  display: grid;
  grid-template-columns: 100%;

  @media (min-width: 640px) {
    grid-gap: 30px 90px;
    grid-template-rows: auto 1fr 32px;
    grid-template-columns: 5fr 6fr;
  }
`

const ImageSlides = styled(Slides)`
  grid-area: 2 / 1 / 2 / 1;
  border-radius: ${({ theme }) => theme.size.borderRadius};

  @media (min-width: 640px) {
    grid-area: 2 / 1 / 4 / 1;
  }

  @media (min-width: 640px) {
    grid-area: 2 / 1 / 4 / 1;
  }
`

const FeaturedStoriesSliderImage = styled(Image)`
  height: 448px;
  max-width: 560px;
  border-radius: ${({ theme }) => theme.size.borderRadius};

  img {
    object-fit: cover;
  }
`

const PostTags = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
`

const ContentSlides = styled(Slides)`
  grid-area: 3 / 1 / 3 / 1;

  @media (min-width: 640px) {
    grid-area: 2 / 2 / 2 / 2;
  }

  ${SecondaryHeading} {
    margin-bottom: 0;
    line-height: 1.5;
  }
`

const FeaturedTag = styled(Tag)`
  @media (max-width: ${({ theme }) =>
      theme.breakpoint.mobileHorizontal - 1}px) {
    margin-bottom: 15px;
  }
`

interface Props {
  posts: BlogPostPreview[]
  fallbackImage?: IGatsbyImageData
}

const FeaturedStoriesSlider: AppFunctionComponent<Props> = (props) => {
  const fallbackImage = useFallbackImage()

  return (
    <FeaturedStoriesSliderInternal {...props} fallbackImage={fallbackImage} />
  )
}

const FeaturedStoriesSliderInternal: AppFunctionComponent<Props> = ({
  posts,
  fallbackImage,
}) => {
  const title = "Featured stories"
  return (
    <ContainerSlider title={title}>
      <Heading renderAs={"h2"}>{title}</Heading>
      <ContentSlides>
        {posts.map((post, index) => {
          const {
            postTitle,
            postSlug,
            postExcerpt: { postExcerpt },
            postTags,
            postOriginalPublishDate,
            postOriginalPublishDateFormatted,
          } = post
          return (
            <ContentWraper key={index}>
              <SecondaryHeading renderAs={"h3"}>
                <NeutralBoldRawLink
                  to={`/community/blog/${postSlug}`}
                  id={`featured-${postSlug}`}
                >
                  {postTitle}
                </NeutralBoldRawLink>
              </SecondaryHeading>
              <PostDate dateTime={postOriginalPublishDate}>
                {postOriginalPublishDateFormatted}
              </PostDate>
              <p>{postExcerpt}</p>
              <PostTags>
                {postTags &&
                  postTags.map((tag, i) => (
                    <FeaturedTag raw key={i}>
                      {tag.text}
                    </FeaturedTag>
                  ))}
              </PostTags>
            </ContentWraper>
          )
        })}
      </ContentSlides>
      <ImageSlides>
        {posts.map((post, index) => {
          const {
            postSlug,
            postFeaturedImage: { gatsbyImageData },
          } = post
          return (
            <BaseLink
              key={index}
              to={`/community/blog/${postSlug}`}
              aria-labelledby={`featured-${postSlug}`}
            >
              <FeaturedStoriesSliderImage
                image={gatsbyImageData || fallbackImage}
              />
            </BaseLink>
          )
        })}
      </ImageSlides>
      <FeaturedStoriesNavigation title={title} />
    </ContainerSlider>
  )
}
export default FeaturedStoriesSlider
